<template>
    <v-container>

    </v-container>
</template>
<script>
// Used for only to update the totalNotification
export default {
    components:{
    //
    },
    computed:({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
          
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchTickNotificationApi = this.fetchTickNotification();
            let fetchNewTotalNotificationApi = this.fetchNewTotalNotification();
            this.$axios.all([
                this.$api.fetch(fetchTickNotificationApi),
                this.$api.fetch(fetchNewTotalNotificationApi),
            ]).then(()=>{
                this.$router.push({name:'NotificationDetail',params:{
                    'id':this.$router.history.current.params.id,
                }});
            });

        },
        fetchTickNotification(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/notification/tick/"+this.$router.history.current.params.id;
            return tempApi;
        },
        fetchNewTotalNotification(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/notification/total/user/"+this.$store.getters.getEmail;
            return tempApi;
        },
        redirectToNotificationDetail(){

        },
    }
}
</script>